import {getHProduceMyTasksUrl} from '@hconnect/common/utils/tasks.utils'
import {Link, Popover} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {AssignmentTurnedInOutlined} from '@material-ui/icons'
import React, {SyntheticEvent, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {MenuLinksBox} from '../../lib2/shell/MenuLinksBox'
import DropdownMenuButtonLink from '../Molecules/AccountDropdown.DropdownMenuButtonLink'
import AccountDropdownLogoutButton from '../Molecules/AccountDropdown.LogoutButton'
import {Action} from '../Molecules/AccountDropdown.types'
import {Locales, LanguageSwitcher} from '../Molecules/LanguageSwitcher'
import Typography from '../Molecules/Typography'

import HProduceAccountButton from './HProduceAccountButton'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2.5),
    maxWidth: 326,
    background: 'rgb(247, 247, 247)'
  },

  activeMenuButton: {
    backgroundColor: theme.palette.grey[100],
    boxShadow: 'none'
  },

  accountButton: {},

  box: {
    padding: `${theme.spacing(2)}px`
  },

  email: {
    overflowWrap: 'break-word'
  }
}))

interface Content {
  header?: string | null
  body: React.ReactNode
}

interface Profile {
  name: string
  email: string
}

interface Link {
  title: string
  url: string
  icon: React.ElementType
}

interface HProduceAccountDropdownProps {
  actions: Action
  logoutButtonText: string
  profile?: Profile
  content?: Content
  locales?: Locales
  userPage?: string
  enableMyTasks?: boolean
}

export const HProduceAccountDropdown: React.FC<HProduceAccountDropdownProps> = ({
  actions,
  logoutButtonText,
  profile,
  content,
  locales,
  userPage,
  enableMyTasks
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()
  const {t} = useTranslation()
  const handleClick = (event: SyntheticEvent<EventTarget>) =>
    setAnchorEl(event.currentTarget as HTMLInputElement)

  const history = useHistory()
  const handleClose = () => setAnchorEl(null)

  const links = useMemo(() => {
    const updatedLinks: Link[] = []
    if (enableMyTasks) {
      updatedLinks.push({
        title: t('myTasks', 'My Tasks'),
        url: getHProduceMyTasksUrl(),
        icon: AssignmentTurnedInOutlined
      })
    }
    return updatedLinks
  }, [enableMyTasks, t])

  return (
    <div className={classes.accountButton}>
      <HProduceAccountButton
        iconButtonClassName={anchorEl ? classes.activeMenuButton : ''}
        onClick={(e) => handleClick(e)}
      />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{paper: classes.paper}}
        data-test-id="account-menu-popover"
      >
        {profile && (
          <div className={classes.box}>
            <Typography
              variant="body1"
              color="textPrimary"
              data-test-id="account-menu-profile-name"
            >
              {profile.name}
            </Typography>
            <Typography
              variant="body2"
              customColor="textPrimarySoft"
              data-test-id="account-menu-profile-email"
              className={classes.email}
            >
              {profile.email}
            </Typography>
            {userPage && (
              <Link href={userPage}>{t('accountDropdown.goToUserPage', 'Go to user page')}</Link>
            )}
          </div>
        )}
        {content && (
          <div className={classes.box}>
            {content.header && (
              <Typography
                variant="body2"
                color="textPrimary"
                data-test-id="account-menu-profile-accounts"
              >
                {content.header}
              </Typography>
            )}
            {content.body}
          </div>
        )}
        {links &&
          links.map((link) => (
            <MenuLinksBox key={link.url}>
              <DropdownMenuButtonLink
                icon={link.icon}
                text={link.title}
                onClick={() => history.push(link.url)}
              />
            </MenuLinksBox>
          ))}
        {locales && (
          <LanguageSwitcher
            locales={locales}
            onSelectLanguage={(lang: string) => {
              if (actions.selectLanguage) {
                actions.selectLanguage(lang)
              }
            }}
          />
        )}
        <AccountDropdownLogoutButton text={logoutButtonText} actions={actions} />
      </Popover>
    </div>
  )
}
